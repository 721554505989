import React, { useState } from "react";
import { Helmet } from "react-helmet";
import Nav from "../components/Nav";
import Footer from "../components/Footer";
import PaymentModal from "../components/PaymentModal";

function WhoWeAre() {
  const [showModal, setShowModal] = useState(false);

  function handleCloseModal() {
    setShowModal(false);
  }

  function handleOpenModal() {
    setShowModal(true);
  }
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Sunrise Speech and Swallowing Therapy | Services</title>
        <link
          rel="canonical"
          href="https://sunrisespeechandswallowing.com/services"
        />
        <meta
          name="description"
          content="Sunrise Speech and Swallowing Therapy offers multiple speech therapy services"
        />
        <meta
          name="keywords"
          content="Speech therapy, Swallowing therapy, Asheville, NC, Services"
        />
        <meta name="author" content="Jay Hoots" />
      </Helmet>
      <main>
        <Nav openModal={handleOpenModal} />
        {showModal && <PaymentModal closeModal={handleCloseModal} />}

        <section className="bg-white border-b py-8 mt-8">
          <div className="container max-w-l mx-auto m-8 text-left mb-4">
            <h1 className="w-full my-2 mt-12 text-5xl font-bold leading-tight text-center text-sunrise-blue">
              Services
            </h1>
            <div className="flex justify-center pl-8 pr-8">
              <ul>
                <li className="mb-3 text-lg">
                  Evaluation and treatment of swallowing disorders (dysphagia)
                </li>
                <li className="mb-3 text-lg ">
                  Evaluation and treatment of speech and language disorders
                  including:
                  <ul className="ml-8 text-base">
                    <li className="list-disc">
                      {" "}
                      Language assessment for Aphasia
                    </li>
                    <li className="list-disc"> Cognitive assessment</li>
                    <li className="list-disc">
                      Motor speech assessment for dysarthria and apraxia of
                      speech
                    </li>
                  </ul>
                </li>
                <li className="text-lg">
                  Assessment and treatment of vocal cord dysfunction (VCD)
                </li>
              </ul>
            </div>
          </div>
        </section>

        <Footer openModal={handleOpenModal} />
      </main>
    </>
  );
}

export default WhoWeAre;
